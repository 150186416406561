*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
}
.home{
  height: 100vh;
  width: 100vw;
  background-color: black;
  background-image: url("./images/bg2.jpg");
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .nav{
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100vw;
  background: linear-gradient(to bottom,#000,#00000000);
  /* backdrop-filter: blur(10px); */
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .nav h2{
  font-family:  "Playwrite HR", cursive;
}
.home .box{
  display: inline-block;
  z-index: 2;
}
.home .box .input{
  position: relative;
}
.home .box .input label{
  /* background: #000; */
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 20px;
  color: white;
  transform: translate(0%,-50%);
  transition: .3s ease;
  z-index: 0;
}
.home .box .input .active-label{
  transform: none;
  top: 56px;
  left: 8px;
  opacity: 0;
  color: #000;
}
.home .box .button{
  height: 50px;
  width: 100%;
}
.home .box button{
  font-size: 20px;
  margin-top: -10px;
  transition: .3s ease;
  display: inline-block;
  opacity: 0;
  width: auto;
  height: auto;
  
  overflow: hidden;
  background-color: white;
  background-color: #c4c4c454;
  backdrop-filter: blur(10px);
  border: 1px solid transparent;
  color:white;
  padding: 5px 10px;
}
.home .box .active-button{
  margin-top: 10px;
  width: auto;
  height: auto;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #fff;
  opacity: 1;
}
.home .box .active-button:hover{
  background: white;
  color: #000;
}
.home .box .input input{
  padding: 5px 10px;
  outline: none;
  font-size: 25px;
  color: white;
  max-width: 300px;
  border: 1px solid white;
  background-color: #c4c4c454;
  backdrop-filter: blur(10px);
}
.dict{
  min-height: 100vh;
  width: 100vw;
  background: url("../src/images/bg2.jpg");
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.dict .nav{
  height: 100px;
  width: 100vw;
  background: linear-gradient(to bottom,#000,transparent);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dict .nav h2{
  font-family:  "Playwrite HR", cursive;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
}
.dict .nav .input input{
  padding: 5px 10px;
  outline: none;
  font-size: 20px;
  color: white;
  max-width: 300px;
  border: 1px solid white;
  background-color: #c4c4c454;
  backdrop-filter: blur(10px);
  cursor: text;
}
.dict .body{
  min-height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.dict .body .content{
  width: 95vw;
  max-width: 400px;
  background-color: #c4c4c454;
  backdrop-filter: blur(10px);
  border: 1px solid white;
  min-height: 80vh;
  box-shadow:0px 0px 1px #fff;
  margin-top: 20px;
}

.dict .body .content .main{
  margin: 10px;
  height: 100%;
}
.dict .body .content .main .head{
  font-size: 20px;
  text-transform: capitalize;
  color: white;
}
.dict .body .content .main .head h2{
  color: rgb(202, 202, 202);
}
.dict .body .content .main .head h2 span{
  /* text-decoration: underline; */
  color: white;
}
.dict .body .content .main .container{
  margin: 5px 0;
}

.dict .body .content .main .container h3{
  color: rgb(255, 255, 255);
}
.dict .body .content .main .container .data{
  border-bottom: 1px solid white;
}
.dict .body .content .main .container .data .part{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  border-bottom: 1px solid #e6e6e6;
}
.dict .body .content .main .container .data .part .sub-part{
  display: flex;
  flex-wrap: wrap;
}
.dict .body .content .main .container .data .part .sub-part p{
  background: #ffffffa6;
  color: #000;
  width: fit-content;
}
.dict .body .content .main .container .data .part p{
  background: #fff;
  backdrop-filter: blur(10px);
  color: #000;
  margin:2px;
  backdrop-filter: blur(10px);
  border: 1px solid #fff;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 2px;
}
.dict .body .content .main .container .data .part p span{
  cursor: pointer;
  transition: .3s ease;
}
.dict .body .content .main .container .data .part p span:hover{
  background-color: #000;
  color: white;
}
.w-50{
  width: 50px !important;
}
.w-100{
  width: 100px !important;
}